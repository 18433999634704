import { Avatar, Button, Modal, Popconfirm, Table, Tag } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { TbDotsVertical } from "react-icons/tb";
import { FiPlus } from "react-icons/fi";
import { GoDotFill } from "react-icons/go";
import { FaPlus, FaUserAlt } from "react-icons/fa";
import CreateUser from "../../components/Form/CreateUser";
import toast from "react-hot-toast";
import {
  useDeleteUserMutation,
  useGetAllUsersQuery,
  useGetUserByIdMutation,
} from "../../store/services/userApi";
import { IoMdClose } from "react-icons/io";

const version = process.env.REACT_APP_TAG_VERSION;

export default function People() {
  const [modalOpen, setModalOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(false);

  const [deleteUser] = useDeleteUserMutation();
  const [getUserByID] = useGetUserByIdMutation();

  const { users, loadingUser } = useGetAllUsersQuery(undefined, {
    selectFromResult: ({ ...result }) => ({
      users: result?.data?.data ?? [],
      loadingUser: result?.isLoading,
    }),
  });

  const handleDeleteUser = useCallback(
    (id) => {
      const response = deleteUser(id);
      void response.then((res) => {
        if (Object.keys(res).includes("error")) {
          toast.error("Erro ao remover usuário");
        } else {
          toast.success("usuário removido com sucesso!!");
        }
      });
    },
    [deleteUser]
  );
  const handleGetUserData = useCallback(
    (id) => {
      const response = getUserByID(id);
      void response.then((res) => {
        if (Object.keys(res).includes("error")) {
          return;
        } else {
          setUserInfo(res?.data);
        }
      });
    },
    [getUserByID]
  );

  const columns = useMemo(() => {
    return [
      {
        title: "Nome",
        dataIndex: "name",
        key: "name",
        // width: "35%",
        render: (text) => (
          <div className="w-full flex gap-3 items-center">
            <Avatar
              size={54}
              className="!w-[34px] h-[34px] md:w-[54px] md:h-[54px]"
            >
              {text ? text?.charAt(0) : <FaUserAlt />}
            </Avatar>
            <span className="w-5/12 xl:w-auto truncate ... font-semibold font-poppins">
              {text}
            </span>
          </div>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (active) => (
          <div className="flex ">
            {active ? (
              <Tag className="flex rounded-full border-[#ABEFC6] bg-[#ECFDF3] text-[#067647] items-center justify-center font-semibold font-poppins">
                <GoDotFill color="#17B26A" /> Ativo
              </Tag>
            ) : (
              <Tag className="flex rounded-full border-[#f9afaf] bg-[#feeeee] text-[#b91515] items-center justify-center font-semibold font-poppins">
                <GoDotFill color="#b91515" /> Inativo
              </Tag>
            )}
          </div>
        ),
      },
      {
        title: "Permissão",
        dataIndex: "permission",
        key: "permission",
        render: (permission) => {
          let color = "border-[#ABEFC6] bg-[#ECFDF3] text-[#067647]";
          switch (permission) {
            case "Super usuário":
              color = "border-[#FCCEEE] bg-[#FDF2FA] text-[#C11574]";
              break;
            case "Colaborador":
              color = "border-[#B2DDFF] bg-[#EFF8FF] text-[#175CD3]";
              break;
            case "Administrador":
              color = "border-[#F9DBAF] bg-[#FEF6EE] text-[#B93815]";
              break;
            default:
          }
          return (
            <Tag className={`rounded-full ${color} font-semibold font-poppins`}>
              {permission}
            </Tag>
          );
        },
      },
      {
        title: "Equipe",
        dataIndex: "equipe",
        key: "equipe",
        render: (_, { equipe }) => (
          <div className="flex items-center ">
            {equipe.length > 0 ? (
              equipe.map((item) => {
                let color = "border-[#ABEFC6] bg-[#ECFDF3] text-[#067647]";
                switch (item) {
                  case "Trabalhista":
                    color = "border-[#B2DDFF] bg-[#EFF8FF] text-[#175CD3]";
                    break;
                  case "Criminal":
                    color = "border-[#E9D7FE] bg-[#F9F5FF] text-[#6941C6]";
                    break;
                  case "Tributário":
                    color = "border-[#FCCEEE] bg-[#FDF2FA] text-[#C11574]";
                    break;
                  case "Empresarial":
                    color = "border-[#B2DDFF] bg-[#EFF8FF] text-[#175CD3]";
                    break;
                  case "Civil":
                    color = "border-[#F9DBAF] bg-[#FEF6EE] text-[#B93815]";
                    break;
                  default:
                }
                return (
                  <Tag
                    key={item}
                    className={`rounded-full ${color} font-semibold font-poppins`}
                  >
                    {item}
                  </Tag>
                );
              })
            ) : (
              <Tag
                className={`rounded-full border-[#ABEFC6] bg-[#ECFDF3] text-[#17B26A]  font-medium py-[6px]`}
              >
                <FaPlus />
              </Tag>
            )}
          </div>
        ),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        render: (record) => (
          <p className="truncate ... text-lg text-[#475467] font-poppins">
            {record}
          </p>
        ),
      },
      {
        dataIndex: "actions",
        key: "actions",
        render: (id) => (
          <div className="w-full justify-center flex gap-3 font-poppins">
            <Popconfirm
              title="Deletar usuário"
              description="Você tem certeza que deseja deletar este usuário?"
              onConfirm={() => {
                handleDeleteUser(id);
              }}
              okText="Confirmar"
              okButtonProps={{
                className: "bg-primary",
              }}
              cancelButtonProps={{
                className: "hover:border-gray-500 hover:text-gray-500",
              }}
              cancelText="Cancelar"
            >
              <span
                className="text-[#475467] font-bold cursor-pointer"
                id={`popoverButton-${id}`}
              >
                Remover
              </span>
            </Popconfirm>

            <span
              className="text-purpleLight font-bold cursor-pointer"
              onClick={() => {
                setModalOpen(true);
                handleGetUserData(id);
              }}
            >
              Editar
            </span>
          </div>
        ),
      },
    ];
  }, [handleDeleteUser, handleGetUserData]);

  const usersData = useMemo(() => {
    return users?.map((item, index) => {
      return {
        key: item?.id,
        name: item?.full_name,
        status: item?.is_active,
        equipe:
          index === 0
            ? []
            : index === 3
            ? ["Tributário", "Empresarial"]
            : index === 1
            ? ["Trabalhista"]
            : index === 2
            ? ["Criminal"]
            : ["Civil"],
        email: item?.email,
        actions: item?.id,
        permission: item?.is_admin
          ? "Administrador"
          : item?.is_superuser
          ? "Super usuário"
          : "Colaborador",
      };
    });
  }, [users]);

  const filterColumns = useMemo(() => {
    if (version === "0.0.4") {
      return columns;
    }
    return columns.filter(
      (column) => column.key !== "equipe" && column.key !== "actions"
    );
  }, [columns]);

  return (
    <div className="w-full block px-4 md:p-2 xl:p-5 relative  ">
      <div className="w-full flex flex-wrap">
        <div className="py-4 flex flex-col gap-2 w-full">
          <div className="flex gap-4 items-center font-semibold text-2xl leading-6">
            <span className="flex h-7 p-1 bg-customPrimary" />
            Gestão de pessoas
          </div>
          <span className="text-[#475467]">
            Gerencie suas equipes e permissões de usuário.
          </span>
          <div className="w-full flex flex-wrap justify-between my-5 gap-4 border-b pb-6">
            <div className="w-full md:w-auto flex flex-col gap-1 relative">
              <span className="text-xl font-bold">Time</span>
              <span className="text-[#475467]">
                Você pode criar uma novo time aqui.
                <TbDotsVertical
                  className="absolute right-0 top-0 cursor-pointer md:hidden"
                  color="#98A2B3"
                  size={23}
                />
              </span>
            </div>
            <div className="w-full  md:w-auto flex flex-wrap gap-4 items-end relative">
              {/* <Button
                className="w-full md:w-auto flex items-center justify-center font-poppins font-semibold text-[#50467A] border-[#6557A4] hover:opacity-70"
                size="large"
              >
                CRIAR NOVO TIME
              </Button> */}
              <Button
                className="w-full md:w-auto  flex items-center justify-center font-semibold font-poppins text-white bg-[#212121] border-[#212121] hover:opacity-70"
                size="large"
                onClick={() => setModalOpen(true)}
              >
                <FiPlus size={22} />
                ADICIONAR NOVO MEMBRO
              </Button>
              {/* <TbDotsVertical
                className="absolute hidden md:flex right-0 top-0 md:top-4 md:!mr-[-30px] cursor-pointer "
                color="#98A2B3"
                size={23}
              /> */}
            </div>
          </div>
          <Table
            columns={filterColumns}
            loading={loadingUser}
            tableLayout="auto"
            dataSource={usersData}
            pagination={false}
            // pagination={{
            //   className: "w-full pb-12 md:pb-6 flex gap-2 justify-center",
            //   position: "bottom",
            //   responsive: true,
            //   showQuickJumper: false,
            //   showSizeChanger: false,
            //   align: "center",
            //   pageSize: 10,
            // }}
            className="w-full overflow-auto pb-16 md:p-0"
            scroll={
              ({
                x: 0,
                y: 520,
              },
              {
                scrollToFirstRowOnChange: true,
              })
            }
          />
        </div>
      </div>
      <Modal
        title={
          <div className="w-full flex justify-center h-14 bg-[#212121] rounded-t-xl px-2 py-3">
            <span className="w-full flex items-center gap-2">
              <span className="text-xl text-white px-4">Adicionar usuário</span>
            </span>
          </div>
        }
        className="custom-modal"
        open={modalOpen}
        footer={""}
        closeIcon={<IoMdClose color="white" size={22} />}
        onCancel={() => {
          setModalOpen(false);
          setUserInfo(undefined);
        }}
        handleCancelModal={() => {
          setModalOpen(false);
          setUserInfo(undefined);
        }}
      >
        <CreateUser
          setModalOpen={setModalOpen}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
        />
      </Modal>
    </div>
  );
}
