import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="w-full flex flex-col items-center justify-center h-screen text-center">
      <h1 className="text-8xl text-[#dc3545] m-0">404</h1>
      <p className="text-8xl text-[#343a40] my-3">Página não encontrada</p>
      <Link
        className="text-lg text-[#007bff] decoration-transparent border-2 border-[#007bff] rounded-md py-3 px-5 transition-colors hover:bg-[#007bff] hover:text-white"
        to="/"
      >
        Voltar para a página inicial
      </Link>
    </div>
  );
};

export default NotFound;
