import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect, useMemo } from "react";

import { useForm, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { useRecoveryResetPasswordMutation } from "../../../store/services/recoveryPasswordApi";
import isTokenValid from "../../../helpers/verifyTokenRecoveryPassword";

const schema = z
  .object({
    password: z.string().min(1, "Senha é obrigatório"),
    repeatPassWord: z.string().min(1, "Repetir senha é obrigatório"),
  })
  .superRefine((fields, ctx) => {
    if (fields.password !== fields.repeatPassWord) {
      ctx.addIssue({
        path: ["repeatPassWord"],
        code: z.ZodIssueCode.invalid_date,
        message: "As senha são diferentes ",
      });
    }
  });

export function useResetPassWord() {
  const { id } = useParams();

  const navigate = useNavigate();

  const [resetPassword] = useRecoveryResetPasswordMutation();

  const { resetpassword } = useParams();

  const {
    control,
    handleSubmit: handleSubmitPassword,
    reset: resetAccountPasswordValues,
    setValue: accountPasswordSetValue,
    setError: accountPasswordSetError,
    formState: { errors: accountPasswordErrors },
  } = useForm({
    mode: "onSubmit",
    resolver: zodResolver(schema),
    defaultValues: {
      password: "",
      repeatPassWord: "",
    },
  });

  const validatePassWord = useWatch({
    control,
    name: "password",
  });

  const passWordValidate = useMemo(() => {
    const passWord = validatePassWord ?? "";

    const specialCharacters = /[!@#$%^&*(),.?":{}|<>]/.test(passWord);

    const hasCapitalLetters = /[A-Z]/.test(passWord);

    const hasNumbers = /\d/.test(passWord);

    const numberCharacters = passWord.length > 7;

    return {
      specialCharacters,
      hasCapitalLetters,
      hasNumbers,
      numberCharacters,
    };
  }, [validatePassWord]);

  const verify = useCallback(() => {
    return (
      <>
        <li
          className={`${
            passWordValidate.numberCharacters
              ? "text-green-600"
              : "text-gray-400"
          }`}
        >
          8 caracteres
        </li>
        <li
          className={`${
            passWordValidate.hasCapitalLetters
              ? "text-green-600"
              : "text-gray-400"
          }`}
        >
          1 Letra maiúscula e minúscula
        </li>
        <li
          className={`${
            passWordValidate.specialCharacters
              ? "text-green-600"
              : "text-gray-400"
          }`}
        >
          1 carácter especial
        </li>
        <li
          className={`${
            passWordValidate.hasNumbers ? "text-green-600" : "text-gray-400"
          }`}
        >
          1 número{" "}
        </li>
      </>
    );
  }, [
    passWordValidate.hasCapitalLetters,
    passWordValidate.hasNumbers,
    passWordValidate.numberCharacters,
    passWordValidate.specialCharacters,
  ]);

  const handleResetPassWord = useCallback(
    (data) => {
      const response = resetPassword({
        newPassword: String(data.password),
        password: String(data.repeatPassWord),
        token: String(id),
      });

      void response.then((res) => {
        if (Object.keys(res).includes("error")) {
          toast.error(
            resetpassword === "resetpassword"
              ? "Erro ao recuperar senha"
              : "Erro ao criar senha"
          );
        } else {
          toast.success(
            resetpassword === "resetpassword"
              ? "Senha alterada com sucesso"
              : "Senha criada com sucesso"
          );
          navigate("/login");
        }
      });
    },
    [id, navigate, resetPassword, resetpassword]
  );

  useEffect(() => {
    if (id && isTokenValid(id)) {
      return;
    } else {
      alert(
        resetpassword === "resetpassword"
          ? "Tempo de recuperação de senha expirou"
          : "Tempo de criação de senha expirou"
      );
      return navigate("/login");
    }
  }, [id, navigate, resetpassword]);

  return {
    accountPasswordControl: control,
    accountPasswordErrors,
    accountPasswordSetError,
    accountPasswordSetValue,
    handleSubmitPassword,
    resetAccountPasswordValues,
    handleResetPassWord,
    verify,
    passWordValidate,
    resetpassword,
  };
}
