import React from "react";
import { useParams } from "react-router-dom";

import ProcessDocumentList from "../../components/ProcessDocumentList";
import Loading from "../../components/Loading";
import {
  useGetProcessDocumentsByIdQuery,
  useGetProcessesByIdQuery,
} from "../../store/services/processesApi";
import NotFound from "../../components/NotFound";

const ProcessDetails = () => {
  const { id } = useParams();
  const { data: getProcessData, isLoading: isLoadingProcess } =
    useGetProcessesByIdQuery(id);

  const { data: processDocumentList, isLoading: isLoadingDocumentList } =
    useGetProcessDocumentsByIdQuery(id);

  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-full h-full p-5">
        {isLoadingProcess || isLoadingDocumentList ? (
          <Loading />
        ) : (
          <div className="w-full h-full ">
            {getProcessData || processDocumentList ? (
              <>
                <div className="flex gap-2 items-center font-semibold md:text-2xl leading-6 my-4">
                  <span className="flex h-7 p-1 bg-secondary" />
                  Detalhes do processo - {getProcessData?.process_number}
                </div>
                <ProcessDocumentList
                  documents={processDocumentList}
                  process={getProcessData}
                />
              </>
            ) : (
              <NotFound />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProcessDetails;
