import { createSlice } from "@reduxjs/toolkit";
import { processesApi } from "../services/processesApi";

const initialState = {
  error: null,
  loading: false,
  process: null,
};

const processSlice = createSlice({
  name: "process",
  initialState,
  reducers: {
    clearProcessSelect(state, { payload }) {
      state.process = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      processesApi.endpoints.getProcessesById.matchPending,
      (state) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      processesApi.endpoints.getProcessesById.matchFulfilled,
      (state, { payload }) => {
        state.process = payload;
      }
    );
    builder.addMatcher(
      processesApi.endpoints.getProcessesById.matchRejected,
      (state, action) => {
        state.error = action.payload;
      }
    );
  },
});

export default processSlice.reducer;

export const { clearProcessSelect } = processSlice.actions;
