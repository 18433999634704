import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().loginSlice.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery,
  tagTypes: ["User"],
  endpoints: (builder) => ({
    getMyUser: builder.query({
      query: () => "/api/v1/users/me",
      providesTags: ["User"],
    }),
    getAllUsers: builder.query({
      query: () => "/api/v1/users",
      providesTags: ["User"],
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `api/v1/users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["User"],
    }),
    getUserById: builder.mutation({
      query: (id) => `/api/v1/users/${id}`,
      invalidatesTags: ["User"],
    }),
    createUser: builder.mutation({
      query: (data) => ({
        url: `api/v1/users/`,
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["User"],
    }),
    editUser: builder.mutation({
      query: (data) => ({
        url: `api/v1/users/${data.id}`,
        method: "PATCH",
        body: {
          email: data.email,
          is_active: true,
          is_superuser: false,
          full_name: data.full_name,
          phone: data.phone.replace(/\s+/g, ""),
          addressCode: data.addressCode,
          street: data.street,
          state: data.state,
          neighborhood: data.neighborhood,
          number: data.number,
          city: data.city,
          photo_url: "",
        },
      }),
      invalidatesTags: ["User"],
    }),
    userInfoUpdate: builder.mutation({
      query: (user) => ({
        url: `api/v1/users/me`,
        method: "PATCH",
        body: user,
      }),
      invalidatesTags: ["User"],
    }),
    updatePasswordUser: builder.mutation({
      query: (passwords) => {
        return {
          url: `/api/v1/users/me/password`,
          method: "PATCH",
          body: passwords,
        };
      },
      invalidatesTags: ["User"],
    }),
  }),
});

export const {
  useGetMyUserQuery,
  useGetAllUsersQuery,
  useUserInfoUpdateMutation,
  useGetUserByIdMutation,
  useDeleteUserMutation,
  useCreateUserMutation,
  useEditUserMutation,
  useUpdatePasswordUserMutation,
} = userApi;
