import React, { useMemo } from "react";
import InvitationLink from "../InvitationLink/InvitationLink";
import { useInviteLinkReferralQuery } from "../../store/services/inviteLinkApi";

export default function ReferralModal() {
  const { data: linkInfo } = useInviteLinkReferralQuery();

  const link = useMemo(() => {
    return linkInfo?.invite_link;
  }, [linkInfo]);

  return (
    <div className="w-full flex flex-col justify-start items-start font-poppins text-start">
      <span className="text-xl text-[#40464F]">
        Convidar para testar o sistema
      </span>
      <div className="flex flex-col gap-4 items-start mt-3">
        <strong className="text-lg">Lexia</strong>
        <span className="text-[#252525]">
          Convide outras pessoas para testarem nossa plataforma e ganhe mais
          solicitações por cada indicação!
        </span>
        <hr className="w-full" />
        <span className="text-[#40464F] text-base">
          Selecione por onde deseja enviar o link:
        </span>
        <InvitationLink link={link} />
      </div>
    </div>
  );
}
