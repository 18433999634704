import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().loginSlice.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const chatApi = createApi({
  reducerPath: "chatApi",
  baseQuery,
  tagTypes: ["Chat"],
  endpoints: (builder) => ({
    sendQuestionRag: builder.mutation({
      query: ({ id, question, session_id }) => {
        return {
          url: `/api/v1/processes/rag/${id}`,
          // url: `/api/v1/processes/rag/537`,
          //TODO: Para testar o rag usar o id 537
          method: "POST",
          body: {
            question: question,
            session_id: session_id ?? "",
          },
        };
      },
      invalidatesTags: ["Chat"],
    }),
    facRequesters: builder.query({
      query: (id) => {
        return {
          url: `/api/v1/faq/${id}`,
          //TODO: Para testar o faq usar o id 537
          // url: `/api/v1/faq/537`,
        };
      },
      providesTags: ["Chat"],
    }),
  }),
});

export const { useSendQuestionRagMutation, useFacRequestersQuery } = chatApi;
