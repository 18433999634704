/* eslint-disable default-case */
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import Profile from "../Form/Profile";
import Credential from "../Form/Credential";
import Company from "../Form/Company/Company";

export default function ConfigPages() {
  const { handle } = useParams();

  const windowsConfig = useMemo(() => {
    switch (handle) {
      case "profile":
        return <Profile />;
      case "credencial":
        return <Credential />;
      case "system":
        return <Company />;
    }
    return <Profile />;
  }, [handle]);

  return windowsConfig;
}
