import React, { useCallback } from "react";
import { Button, Input } from "antd";
import { Controller, useForm } from "react-hook-form";
import CustomInputMask from "../../CustomInputMask";
import { zodResolver } from "@hookform/resolvers/zod";
import Attachment from "../../Attachment";

import { z } from "zod";
import { useFindAddressByCepMutation } from "../../../store/services/findCepApi";
import toast from "react-hot-toast";
import { state } from "../../../data/state";
import {
  useCompanyByIDQuery,
  useUpdateCompanyMutation,
} from "../../../store/services/companyApi";
import { useAppSelector } from "../../../store/hooks";

const schema = z.object({
  id: z.any().optional(),
  name: z.string().min(1, "Nome é obrigatório"),
  CNPJ: z.string().min(1, "CNPJ é obrigatório"),
  photo: z.string().optional(),
  email: z.string().optional(),
  phone: z.string().optional(),
  addressCode: z.string().optional(),
  city: z.string().optional(),
  street: z.string().optional(),
  state: z.string().optional(),
  neighborhood: z.string().optional(),
  number: z.any().optional(),
});

export default function Company() {
  const { userInfo } = useAppSelector((state) => {
    return {
      userInfo: state.userSlice.userData,
    };
  });

  const { data: companyData } = useCompanyByIDQuery(userInfo.firm_id, {
    skip: !userInfo.firm_id,
  });
  const [getAddressInfoByCep] = useFindAddressByCepMutation();

  const [updateCompany] = useUpdateCompanyMutation();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onSubmit",
    resolver: zodResolver(schema),
    values: {
      ...companyData,
      id: companyData?.id,
    },
  });

  const handleFormSubmit = useCallback(
    (data) => {
      const response = updateCompany({
        ...data,
        phone: data?.phone ? data?.phone?.replace(/\s+/g, "") : null,
      });

      response.then((res) => {
        if (Object.keys(res).includes("error")) {
          toast.error("Erro ao atualizar informações da empresa");
        } else {
          toast.success("Informações atualizadas com sucesso");
        }
      });
    },
    [updateCompany]
  );

  const handleGetAddressInfo = useCallback(
    (cep) => {
      if (cep.length === 9) {
        const response = getAddressInfoByCep(cep);

        response.then((res) => {
          if (Object.keys(res).includes("error")) {
            toast.error("Erro ao buscar informações do cep");
          } else {
            const nameState = state[res?.data?.uf];

            setValue("street", res?.data?.logradouro);
            setValue("city", res?.data?.localidade);
            setValue("neighborhood", res?.data?.bairro);
            setValue("state", nameState);
          }
        });
      }
    },
    [getAddressInfoByCep, setValue]
  );

  return (
    <div className="w-full p-4 flex flex-col shadow-login gap-7">
      <span className="text-2xl font-semibold underline underline-offset-8">
        Configurações do sistema
      </span>
      <div className="w-full md:w-auto flex justify-center xl:justify-start items-center">
        <Attachment setValue={setValue} />
      </div>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className="w-full grid grid-flow-row-dense grid-cols-6 gap-5"
      >
        <div className="col-span-6  md:col-span-3">
          <span className="text-[#5C5C5C] text-sm font-normal flex">
            CNPJ<p className="text-red-500">*</p>
          </span>
          <Controller
            control={control}
            name="CNPJ"
            render={({ field: { name, onChange, value } }) => (
              <CustomInputMask
                name={name}
                style={{
                  resize: "none",
                  borderColor: errors?.CNPJ ? "red" : "",
                }}
                mask={"__.___.___/____-__"}
                replacement={{
                  _: /\d/,
                  X: /[0-9]/,
                }}
                dataTestValue="input_cnpj_client"
                onChange={onChange}
                value={value}
                status={errors.CNPJ ? "error" : ""}
                className={`${
                  errors?.CNPJ
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
                placeholder={"Insira o seu CNPJ"}
              />
            )}
          />
          {errors?.cpf && (
            <span className="text-[#fe3535] text-sm">
              {errors?.cpf.message}
            </span>
          )}
        </div>
        <div className="col-span-6 md:col-span-3">
          <span className="text-[#5C5C5C] text-sm font-normal">
            Nome fantasia
          </span>
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <Input
                size="large"
                {...field}
                status={errors.state ? "error" : ""}
                placeholder="Insira o seu Nome"
                className={`${
                  errors?.name
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.name && (
            <span className="text-[#fe3535] text-sm">
              {errors?.name.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 md:col-span-3">
          <span className="text-[#5C5C5C] text-sm font-normal">Email</span>
          <Controller
            control={control}
            name="email"
            render={({ field: { name, onChange, value } }) => (
              <Input
                size="large"
                name={name}
                onChange={onChange}
                value={value}
                status={errors.email ? "error" : ""}
                placeholder="Insira o seu Email"
                className={`${
                  errors?.email
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.email && (
            <span className="text-[#fe3535] text-sm">
              {errors?.email.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 md:col-span-3">
          <span className="text-[#5C5C5C] text-sm font-normal">Telefone</span>
          <Controller
            control={control}
            name="phone"
            render={({ field: { name, onChange, value } }) => (
              <CustomInputMask
                name={name}
                size="large"
                onChange={onChange}
                value={value}
                mask={"(XX) XXXXX-XXXX"}
                replacement={{
                  _: /\d/,
                  X: /[0-9]/,
                }}
                placeholder="Insira o seu Telefone"
                className={`${
                  errors?.phone ? "border-red-600" : ""
                } w-full rounded-sm hover:border-purpleLight focus:border-purpleLight focus:shadow-none`}
              />
            )}
          />
          {errors?.phone && (
            <span className="text-[#fe3535] text-sm">
              {errors?.phone.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 md:col-span-2">
          <span className="text-[#5C5C5C] text-sm font-normal">CEP</span>
          <Controller
            control={control}
            name="addressCode"
            render={({ field: { name, value, onChange } }) => (
              <CustomInputMask
                name={name}
                style={{
                  resize: "none",
                }}
                mask={"_____-___"}
                replacement={{
                  _: /\d/,
                  X: /[0-9]/,
                }}
                onChange={(e) => {
                  onChange(e.target.value);
                  handleGetAddressInfo(e.target.value);
                }}
                value={value}
                placeholder="Insira o seu Cep"
                className={`${
                  errors?.addressCode
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.addressCode && (
            <span className="text-[#fe3535] text-sm">
              {errors?.addressCode.message}
            </span>
          )}
        </div>
        <div className="col-span-6 md:col-span-2">
          <span className="text-[#5C5C5C] text-sm font-normal">Rua</span>
          <Controller
            control={control}
            name="street"
            render={({ field }) => (
              <Input
                {...field}
                size="large"
                placeholder="Insira o nome da rua"
                status={errors.street ? "error" : ""}
                className={`${
                  errors?.street
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.street && (
            <span className="text-[#fe3535] text-sm">
              {errors?.street.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 md:col-span-2">
          <span className="text-[#5C5C5C] text-sm font-normal">Numero</span>
          <Controller
            control={control}
            name="number"
            render={({ field }) => (
              <Input
                {...field}
                size="large"
                type="number"
                status={errors?.number ? "error" : "success"}
                placeholder="Insira o número da rua"
                className={`${
                  errors?.number
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.number && (
            <span className="text-[#fe3535] text-sm">
              {errors?.number.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 md:col-span-2">
          <span className="text-[#5C5C5C] text-sm font-normal">Bairro</span>
          <Controller
            control={control}
            name="neighborhood"
            render={({ field }) => (
              <Input
                {...field}
                size="large"
                status={errors?.neighborhood ? "error" : ""}
                placeholder="Insira o nome do bairro"
                className={`${
                  errors?.neighborhood
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.neighborhood && (
            <span className="text-[#fe3535] text-sm">
              {errors?.neighborhood.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 md:col-span-2">
          <span className="text-[#5C5C5C] text-sm font-normal">Estado</span>
          <Controller
            control={control}
            name="state"
            render={({ field }) => (
              <Input
                {...field}
                size="large"
                status={errors?.state ? "error" : ""}
                placeholder="Insira o nome do Estado"
                className={`${
                  errors?.state
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.state && (
            <span className="text-[#fe3535] text-sm">
              {errors?.state.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 md:col-span-2">
          <span className="text-[#5C5C5C] text-sm font-normal">Cidade</span>
          <Controller
            control={control}
            name="city"
            render={({ field }) => (
              <Input
                {...field}
                size="large"
                status={errors?.city ? "error" : ""}
                placeholder="Insira o Complemento"
                className={`${
                  errors?.city
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.city && (
            <span className="text-[#fe3535] text-sm">
              {errors?.city.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 gap-3 md:gap-0 flex flex-col md:flex-row justify-between mt-4">
          {isDirty && (
            <Button
              size="large"
              className="text-[#212121] border-[#212121] hover:text-red-500 hover:border-red-500 font-semibold rounded px-6"
            >
              CANCELAR
            </Button>
          )}
          <div className="flex flex-col md:flex-row gap-3 ms-auto">
            <Button
              size="large"
              htmlType="submit"
              className="text-white bg-[#212121] hover:opacity-70 hover:border-[#212121] font-semibold rounded px-6"
            >
              {isDirty ? "ATUALIZAR" : "EDITAR"}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
