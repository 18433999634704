import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebarOpen: false,
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setSidebarOpen(state, { payload }) {
      state.sidebarOpen = payload;
    },
  },
  extraReducers: () => {},
});

export default sidebarSlice.reducer;

export const { setSidebarOpen } = sidebarSlice.actions;
