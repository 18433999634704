import { logout } from "../store/redux/loginRedux";

export const authMiddleware = (storeAPI) => (next) => (action) => {
  if (
    action.type.endsWith("/rejected") &&
    action?.payload?.data?.detail?.includes("not validate credentials") &&
    (action.payload?.status === 401 || action.payload?.status === 403)
  ) {
    storeAPI.dispatch(logout());
  }
  return next(action);
};
