import React from "react";
import { useParams } from "react-router-dom";
import RegisterUser from "../../components/Form/RegisterUser/RegisterUser";
import ManagerRegisterCompanySteps from "../../components/Form/RegisterCompanySteps/ManagerRegisterSteps";
import { Toaster } from "react-hot-toast";
import SuccessRegister from "../../components/SuccessRegister/SuccessRegister";

export default function Register() {
  const { id } = useParams();

  return (
    <div className="w-screen flex relative items-center h-screen overflow-hidden bg-white">
      <Toaster />

      <div className="w-full h-full overflow-auto xl:w-6/12 flex">
        {id === "user" ? (
          <RegisterUser />
        ) : id === "success" ? (
          <SuccessRegister />
        ) : (
          <ManagerRegisterCompanySteps />
        )}
      </div>

      <div className="hidden h-full md:w-6/12 xl:flex justify-center">
        <img
          className="w-full h-full object-cover"
          src="/img/hammer-img-login.png"
          alt="imagem-hemmer-login"
        />
      </div>
      {/* <div className="w-full text-xs text-center flex-col-reverse gap-3 md:gap-0 md:flex-row md:text-start md:text-base  flex absolute bottom-0 text-white bg-[#979797] justify-between items-center p-2">
      © Copyright - Biofy Technologies | Todos os Direitos Reservados 2024
      <div className="mr-10 flex items-center justify-center gap-3">
        <img className="w-20 h-full" src="/img/svg/logo.svg" alt="" />
        <img className="w-20 h-full" src="/img/svg/partner.svg" alt="" />
      </div>
    </div> */}
    </div>
  );
}
