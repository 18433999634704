import React, { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import RegisterCompany from "../RegisterCompany/RegisterCompany";
import RegisterUser from "../RegisterUser/RegisterUser";
import { useRegisterCompanyMutation } from "../../../store/services/companyApi";

export default function ManagerRegisterCompanySteps() {
  const { token } = useParams();

  const navigate = useNavigate();

  const [addCompany] = useRegisterCompanyMutation();

  const [step, setStep] = useState(0);
  const [stepData, setStepData] = useState(null);

  const handleNextStep = (data) => {
    switch (step) {
      case 1:
        setStepData((prevData) => {
          const dataValue = { ...prevData, ...data };
          handleSendForm(dataValue);
          return dataValue;
        });
        break;
      default:
    }
  };

  const handleSendForm = useCallback(
    (data) => {
      const customData = {
        firm_in: {
          name: data.companyName,
          cnpj: data?.CNPJ.replace(/\D/g, ""),
        },
        user_in: {
          full_name: data.full_name,
          email: data?.email,
          cpf: data?.cpf.replace(/\D/g, ""),
          link_id: token,
        },
      };

      const response = addCompany({
        company: customData,
        token,
      });

      response
        .then((res) => {
          if (Object.keys(res).includes("error")) {
            return toast.error("Error ao criar empresa");
          }
          navigate("/register/success");
        })
        .catch();
    },
    [addCompany, navigate, token]
  );

  return (
    <div className="w-full flex overflow-auto overflow-x-hidden">
      {step === 0 && (
        <RegisterCompany
          setStep={setStep}
          setStepData={setStepData}
          stepData={stepData}
        />
      )}
      {step === 1 && (
        <RegisterUser
          onSubmit={handleNextStep}
          stepData={stepData}
          setStep={setStep}
        />
      )}
    </div>
  );
}
