import React from "react";

import Router from "./routes/router";

const App = () => {
  return (
    <div className="min-h-full flex justify-center items-center bg-light font-poppins">
      <Router />
    </div>
  );
};

export default App;
