import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().loginSlice.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const pjeApi = createApi({
  reducerPath: "pjeApi",
  baseQuery,
  tagTypes: ["PJE"],
  endpoints: (builder) => ({
    getCredentialsPJE: builder.query({
      query: () => "/api/v1/pje/credentials",
      providesTags: ["PJE"],
    }),
    validateCredentialsPJE: builder.mutation({
      query: ({ usernamePje, passwordPje }) => {
        return {
          url: `/api/v1/pje/credentials`,
          method: "POST",
          body: {
            usernamePje,
            passwordPje,
          },
        };
      },
      invalidatesTags: ["PJE"],
    }),
    deleteCredentialsPJE: builder.mutation({
      query: (id) => {
        return {
          url: `/api/v1/pje/credentials/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["PJE"],
    }),
  }),
});

export const {
  useValidateCredentialsPJEMutation,
  useGetCredentialsPJEQuery,
  useDeleteCredentialsPJEMutation,
} = pjeApi;
