import React, { useEffect, useMemo, useState } from "react";
import { format, parseISO, startOfWeek, startOfMonth } from "date-fns";
import { Button, Select, Steps, Tabs } from "antd";
import { FaDotCircle } from "react-icons/fa";
import { FaRegDotCircle } from "react-icons/fa";
import { GoCheckCircleFill } from "react-icons/go";
import { useWindowSize } from "@uidotdev/usehooks";
import PDFViewer from "../ViewPDF";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";

const version = process.env.REACT_APP_TAG_VERSION;

const TimelineList = ({
  items,
  renderItem,
  dateField = "creation_date",
  groupBy = null,
  ascending = false,
  process,
}) => {
  const [dateProcessSelect, setDateProcessSelect] = useState();
  const [tipeVisualizationSelect, setTipeVisualizationSelect] = useState();

  const window = useWindowSize();

  const sortedItems = items.sort(
    (a, b) => new Date(a[dateField]) - new Date(b[dateField])
  );
  if (!ascending) {
    sortedItems.reverse();
  }

  const groupedItems = sortedItems.reduce((acc, item) => {
    let date;
    if (groupBy === "week") {
      date = format(startOfWeek(parseISO(item[dateField])), "yyyy-MM-dd");
    } else if (groupBy === "month") {
      date = format(startOfMonth(parseISO(item[dateField])), "yyyy-MM-dd");
    } else {
      date = format(parseISO(item[dateField]), "yyyy-MM-dd");
    }
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(item);
    return acc;
  }, {});

  useEffect(() => {
    if (!dateProcessSelect && groupedItems) {
      setDateProcessSelect({
        process: groupedItems[0]?.find((item) => item?.id),
        index: 0,
      });
    }
  }, [dateProcessSelect, groupedItems]);

  const listSelectProcess = useMemo(() => {
    return Object.keys(groupedItems).flatMap((date) => {
      return groupedItems[date].map((item) => item);
    });
  }, [groupedItems]);

  const processSelectData = useMemo(() => {
    const projectSelect = listSelectProcess.find((item) => item);

    if (dateProcessSelect?.process) {
      return dateProcessSelect?.process;
    } else if (projectSelect) {
      return projectSelect;
    }
  }, [dateProcessSelect, listSelectProcess]);

  return (
    <div className="w-full flex flex-col md:flex-row gap-8  pb-16">
      <div className="md:w-[300px] h-fit  flex flex-col p-6 shadow-lg gap-4">
        <span className="text-2xl">Timeline</span>
        <Steps
          direction={
            window.width && window.width >= 768 ? "vertical" : "horizontal"
          }
          responsive={true}
          className="w-full flex items-start relative max-h-[45lvh] overflow-auto"
          current={Object.keys(groupedItems).length - 1}
          items={Object.keys(groupedItems).map((item, index) => {
            const processFind = groupedItems[item].find((item) => item?.id);
            return {
              title: format(parseISO(item), "dd/MM/yyyy"),
              status: index === dateProcessSelect?.index ? "process" : "wait",
              className: `cursor-pointer`,
              onClick: () =>
                setDateProcessSelect({ process: processFind, index: index }),
              description: (
                <span className="w-full overflow-hidden text-ellipsis truncate ...">
                  {Object.keys(groupedItems).length - 1 === index
                    ? "Iniciado"
                    : process?.labels_primary}
                </span>
              ),
              icon:
                index === 0 ? (
                  <FaDotCircle
                    color="#212121"
                    className=" border-4 ms-[2px] rounded-full border-[#f5d0f7]"
                    size={28}
                  />
                ) : Object.keys(groupedItems).length - 1 === index ? (
                  <FaRegDotCircle color="#D0D5DD" className="ms-1" />
                ) : (
                  <GoCheckCircleFill color="#f2a541" className="ms-1" />
                ),
            };
          })}
        />
        {processSelectData?.id && (
          <Link
            to={process?.summary_url}
            className="flex decoration-transparent"
          >
            <Button
              className=" xs:text-sm text-base  text-btnPrimary font-semibold px-6 hover:border-borderGray mt-5 disabled:bg-gray-100 disabled:text-gray-400"
              size="large"
              disabled={!process?.summary_url}
            >
              EXPORTAR TIMELINE
              <img src="/img/svg/icons/IconDownload.svg" alt="" />
            </Button>
          </Link>
        )}
      </div>
      <div className="w-full mb-16 md:mb-0">
        <Tabs
          defaultActiveKey="1"
          type="card"
          size={"middle"}
          className=" pb-10"
          items={[
            {
              label: "RESUMO",
              key: 1,
              className: "border border-t-0 rounded-md",
              children: (
                <div className="w-full h-[40lvh] md:h-[55lvh] flex flex-col overflow-auto px-3">
                  {processSelectData?.id ? (
                    <React.Fragment>
                      {renderItem(
                        processSelectData,
                        processSelectData?.id,
                        "Resumo - Simples"
                      )}
                    </React.Fragment>
                  ) : (
                    <div className="w-full h-full flex justify-center items-center">
                      <span className="font-medium">
                        Processo foi criado, porem nenhum dado foi gerado
                        ainda...
                      </span>
                    </div>
                  )}
                </div>
              ),
            },
            {
              label: "ORIGINAL",
              key: 2,
              className: "border border-t-0",
              children: (
                <div
                  className={`w-full ${
                    processSelectData?.id ? "" : "h-[40lvh]"
                  } min-h-[40lvh] md:min-h-[55lvh] flex flex-col overflow-auto px-3 items-end`}
                >
                  {processSelectData?.id ? (
                    <React.Fragment>
                      <Link
                        to={processSelectData?.storage_url}
                        className="flex decoration-transparent"
                      >
                        <Button
                          className=" xs:text-sm text-base  text-btnPrimary font-semibold px-6 hover:border-borderGray mt-5"
                          size="large"
                          disabled={
                            !processSelectData ||
                            processSelectData?.length <= 0 ||
                            !processSelectData?.storage_url
                          }
                        >
                          BAIXAR ORIGINAL
                          <img src="/img/svg/icons/IconDownload.svg" alt="" />
                        </Button>
                      </Link>
                      <div className="w-full py-4">
                        {processSelectData?.storage_url && (
                          <PDFViewer pdfUrl={processSelectData?.storage_url} />
                        )}
                      </div>
                    </React.Fragment>
                  ) : (
                    <div className="w-full h-full flex justify-center items-center">
                      <span className="font-medium">
                        Processo foi criado, porem nenhum dado foi gerado
                        ainda...
                      </span>
                    </div>
                  )}
                </div>
              ),
            },
          ]
            .concat(
              version === "0.0.4" && {
                label: "COMPARATIVO",
                key: 3,
                className: "border border-t-0",
                children: (
                  <div
                    className={`w-full ${
                      processSelectData?.id ? "" : "h-[40lvh]"
                    } min-h-[40lvh] md:min-h-[55lvh] flex flex-col overflow-auto px-3 items-end py-3`}
                  >
                    {processSelectData?.id ? (
                      <>
                        <div className="xl:w-5/12 2xl:w-3/12 flex flex-col">
                          <span>Versão de comparação</span>
                          <Select
                            placeholder="Escolha a versão gerada"
                            options={[
                              {
                                label: "Simples",
                                value: 1,
                              },
                              {
                                label: "Detalhado",
                                value: 2,
                              },
                            ]}
                            onChange={(_, object) =>
                              setTipeVisualizationSelect(object.label)
                            }
                          />
                        </div>
                        <div className="w-full flex-col xl:flex-row flex gap-4">
                          <div className="xl:w-6/12 py-4">
                            {processSelectData?.storage_url && (
                              <PDFViewer
                                pdfUrl={processSelectData?.storage_url}
                              />
                            )}
                          </div>
                          {tipeVisualizationSelect && (
                            <div className="xl:w-6/12">
                              {renderItem(
                                processSelectData,
                                null,
                                tipeVisualizationSelect
                                  ? tipeVisualizationSelect
                                  : ""
                              )}
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <div className="w-full h-full flex justify-center items-center">
                        <span className="font-medium">
                          Processo foi criado, porem nenhum dado foi gerado
                          ainda...
                        </span>
                      </div>
                    )}
                  </div>
                ),
              }
            )
            .concat(
              process.summary_whole_process_plain_text && {
                label: "RESUMO DO PROCESSO COMPLETO",
                key: 5,
                className: "border border-t-0",
                children: (
                  <div
                    className={`w-full min-h-[40lvh] md:h-[65lvh] flex flex-col overflow-auto p-3 gap-5`}
                  >
                    <span className="w-full flex text-base md:text-3xl font-semibold mt-3">
                      Resumo do processo
                    </span>

                    <div className="w-full h-full">
                      <ReactMarkdown>
                        {process.summary_whole_process_plain_text}
                      </ReactMarkdown>
                    </div>
                  </div>
                ),
              }
            )}
        />
      </div>
    </div>
  );
};

export default TimelineList;
