import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const cepApi = createApi({
  reducerPath: "cepApi",
  tagTypes: ["CEP"],
  baseQuery: fetchBaseQuery({
    baseUrl: `https://viacep.com.br/ws/`,
  }),
  endpoints: (builder) => ({
    findAddressByCep: builder.mutation({
      query: (cep) => {
        return {
          url: `${cep}/json/`,
        };
      },
      invalidatesTags: ["CEP"],
    }),
  }),
});

export const { useFindAddressByCepMutation } = cepApi;
