/* eslint-disable no-mixed-operators */
import React from "react";
import { format, parseISO } from "date-fns";
import { Button } from "antd";

const DocumentCard = ({ document, index, typeResume }) => {
  return (
    document?.document_datetime && (
      <div className="w-full p-5 mx-auto bg-white flex-col gap-4">
        <div className="flex justify-between items-center pb-2">
          <div className="flex items-center gap-3">
            <span className="text-2xl font-semibold flex">{`${
              index ? "Resumo -" : ""
            } ${typeResume}`}</span>
            {index && (
              <Button>
                <img src="/img/svg/icons/IconDownload.svg" alt="" />
                Download PDF
              </Button>
            )}
          </div>
          {(document?.document_datetime && index) ||
          (document?.document_datetime && typeResume === "Simples") ? (
            <span className="text-xl font-bold text-secondary">
              {format(parseISO(document?.document_datetime), "dd/MM/yyyy")}
            </span>
          ) : null}
        </div>
        <div className="flex flex-col gap-3">
          {document.assunto && (
            <p className="text-base text-dark m-0">
              <strong>Assunto:</strong> {document.assunto}
            </p>
          )}
          {document.exequente && (
            <p className="text-base text-[#343a40] m-0">
              <strong>Exequente:</strong> {document.exequente}
            </p>
          )}
          {document.executado && (
            <p className="text-base text-[#343a40] m-0">
              <strong>Executado:</strong> {document.executado}
            </p>
          )}
          {document.local_de_julgamento && (
            <p className="text-base text-[#343a40] m-0">
              <strong>Local de Julgamento:</strong>{" "}
              {document.local_de_julgamento}
            </p>
          )}
          {document.data_de_assinatura && (
            <p className="text-base text-[#343a40] m-0">
              <strong>Data de Assinatura:</strong> {document.data_de_assinatura}
            </p>
          )}
          {document.magistrado && (
            <p className="text-base text-[#343a40] m-0">
              <strong>Magistrado:</strong> {document.magistrado}
            </p>
          )}
          <div>
            <strong>Resumo:</strong>

            <ul className="list-disc pl-5">
              {document?.resumo?.map((item, index) => (
                <li className="text-base text-[#495057] mb-1" key={index}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )
  );
};

export default DocumentCard;
