import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { Avatar, Dropdown, Modal } from "antd";
import { FaUserAlt } from "react-icons/fa";
import { FiLogOut, FiSettings } from "react-icons/fi";
import { RiUserAddLine } from "react-icons/ri";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { processesApi } from "../../store/services/processesApi";
import { userApi } from "../../store/services/userApi";
import { loginApi } from "../../store/services/loginApi";
import { logout } from "../../store/redux/loginRedux";
import ReferralModal from "../ReferralModal";

const Header = ({ toggleSidebar }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [openModal, setModalOpen] = useState(false);

  const { userInfo } = useAppSelector((state) => {
    return {
      userInfo: state.userSlice,
    };
  });

  const handleLogout = () => {
    dispatch(logout());
    dispatch(processesApi.util.resetApiState(["Processes"]));
    dispatch(userApi.util.resetApiState(["User"]));
    dispatch(loginApi.util.resetApiState(["Login"]));
  };

  const items = [
    {
      key: "1",
      label: (
        <div className="w-full flex flex-col items-end justify-end">
          <div
            className={`max-w-52 w-full decoration-transparent text-white text-lg font-bold flex items-center justify-end gap-4 py-2 line-clamp-1`}
          >
            {userInfo?.userData?.full_name}
          </div>
          <hr className="w-full mt-3" />
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div className="w-full flex flex-col items-end justify-end ">
          <Link
            className={`min-w-52 w-full decoration-transparent text-white text-lg font-bold flex items-center justify-end gap-4 hover:opacity-70 py-2`}
            to={"/config"}
          >
            Configurações
            <FiSettings size={25} />
          </Link>
          <hr className="w-full mt-3" />
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div className="w-full flex flex-col items-end justify-end ">
          <div
            className={`min-w-52 w-full decoration-transparent text-white text-lg font-bold flex items-center justify-end gap-4 hover:opacity-70 py-2 cursor-pointer`}
            onClick={() => setModalOpen(true)}
          >
            Indicar a Lexia
            <RiUserAddLine size={25} color="white" />
          </div>
          <hr className="w-full mt-3" />
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div
          className={` w-full decoration-transparent text-white text-lg font-bold flex items-center justify-end gap-4 hover:opacity-70 py-2`}
          onClick={handleLogout}
        >
          Sair
          <FiLogOut size={25} color="white" />
        </div>
      ),
    },
  ];

  return (
    <div className="w-full bg-[#212121] text-white h-[60px] p-1 flex justify-between items-center relative">
      <div className="flex h-full items-center px-6">
        <RxHamburgerMenu
          size={26}
          onClick={toggleSidebar}
          className="cursor-pointer"
        />
        <img
          className="cursor-pointer min-w-16 h-16 select-none"
          src="/img/Artboard 4@2x.png"
          alt=""
          onClick={() => navigate("/dashboard")}
        />
      </div>

      <Dropdown
        menu={{
          theme: "dark",
          className: "w-full bg-[#212121] rounded-md",
          items:
            userInfo?.userData?.is_admin || userInfo?.userData?.is_superuser
              ? items
              : items.filter((obj) => obj.key !== "3"),
        }}
        placement="bottomCenter"
      >
        <Avatar
          className="bg-gray-500 me-4 cursor-pointer "
          size={35}
          icon={<FaUserAlt />}
        />
      </Dropdown>

      <Modal
        open={openModal}
        onCancel={() => setModalOpen(false)}
        width={"680px"}
        footer={<ReferralModal />}
      />
    </div>
  );
};

export default Header;
