import React from "react";
import { useDropzone } from "react-dropzone";
import { FiUploadCloud } from "react-icons/fi";

export default function FileUpload({ outputArray, setOutputArray }) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: ".csv, .xlsx, .txt, .md",
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        const validExtensions = [".csv", ".xlsx", ".txt", ".md"];
        const fileExtension = file.name.slice(
          ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
        );

        if (!validExtensions.includes(`.${fileExtension}`)) {
          alert(
            "Formato de arquivo inválido. Por favor, envie um arquivo CSV, XLSX ou TXT."
          );
          return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          const text = e.target.result;
          const result = text
            .split(/[,;\r\n]+/)
            .map((str) => str.trim())
            .filter(Boolean);
          setOutputArray(result);
        };
        reader.readAsText(file);
      }
    },
  });

  return (
    <div className="flex flex-col items-center justify-center">
      <div
        {...getRootProps()}
        className="border-2 border-dashed border-purple-500 rounded-lg p-6 w-full max-w-lg text-center cursor-pointer hover:bg-purple-50"
      >
        <input {...getInputProps()} />
        <div className="flex flex-col items-center">
          <div className="border p-3 rounded-lg">
            <FiUploadCloud size={28} color="#475467" />
          </div>
          <p className="mt-2 font-medium">
            <span className="text-[#6941C6]">Clique aqui </span>ou solte aqui
            para importar
          </p>
          <p className="mt-1 text-gray-500">CSV, xlsx, txt</p>
        </div>
      </div>
      {outputArray.length > 0 && (
        <div className="mt-6 w-full max-w-lg">
          <h3 className="text-lg font-medium">Lista de anexos:</h3>
          <ul className="list-disc list-inside overflow-auto h-36">
            {outputArray.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
