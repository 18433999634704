import { Controller } from "react-hook-form";

import { Input } from "antd";
import { BiLockAlt } from "react-icons/bi";
import { useResetPassWord } from "./hooks/useResetPassWord";
import { FiLock } from "react-icons/fi";

export function RecoveryAccount() {
  const {
    accountPasswordControl,
    accountPasswordErrors,
    handleSubmitPassword,
    handleResetPassWord,
    verify,
    passWordValidate,
    resetpassword,
  } = useResetPassWord();

  return (
    <div className="w-full h-fullNav overflow-auto flex-col xl:w-6/12 flex justify-center items-center">
      <div className="gap-3 p-5 flex relative flex-col rounded-2xl max-w-[450px] w-full py-32 xs:py-6 shadow-login">
        <div className="flex items-center ">
          <span className="w-1 h-8 p-1 bg-[#f6bf87] me-4" />
          <h3 className="text-[16px] xs:text-[20px] md:text-2xl font-semibold">
            {resetpassword === "resetpassword"
              ? "Área de recuperação de senha"
              : "Área de criação de senha"}
          </h3>
        </div>
        <div className="w-full mt-5">
          <span className="font-semibold">
            Sua senha deve conter no mínimo:
          </span>
          <ul className=" mt-3">{verify()}</ul>
        </div>
        <form
          onSubmit={handleSubmitPassword(handleResetPassWord)}
          className="w-full flex flex-col gap-2"
        >
          <div className="flex flex-col gap-2">
            <span className="text-dark">
              {resetpassword === "resetpassword" ? "Nova senha*" : "Senha"}
            </span>
            {/* Senha atual */}
            <Controller
              control={accountPasswordControl}
              name="password"
              render={({ field }) => (
                <Input.Password
                  {...field}
                  className="text-[16px] px-3 py-2 rounded-md bg-[#E8E8E8] hover:border-purpleLight  focus:border-purpleLight focus-within:border-purpleLight focus:shadow-none"
                  type="password"
                  status={accountPasswordErrors?.password ? "error" : ""}
                  size="large"
                  placeholder="Nova Senha"
                  prefix={<FiLock color="#5e5e5e" className="me-2" />}
                />
              )}
            />
            {accountPasswordErrors?.password && (
              <span className="w-full text-start text-[#fe3535] text-sm">
                {accountPasswordErrors?.password?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <span className="text-dark">Repetir senha*</span>
            {/* Senha atual */}
            <Controller
              control={accountPasswordControl}
              name="repeatPassWord"
              render={({ field }) => (
                <Input.Password
                  {...field}
                  className="text-[16px] px-3 py-2 rounded-md bg-[#E8E8E8] hover:border-purpleLight  focus:border-purpleLight focus-within:border-purpleLight focus:shadow-none "
                  type="password"
                  status={accountPasswordErrors?.repeatPassWord ? "error" : ""}
                  size="large"
                  placeholder="Repetir senha"
                  prefix={<BiLockAlt color="#5E5E5E" className="me-2" />}
                />
              )}
            />
            {accountPasswordErrors?.repeatPassWord && (
              <span className="w-full text-start text-[#fe3535] text-sm">
                {accountPasswordErrors?.repeatPassWord?.message}
              </span>
            )}
          </div>

          <button
            data-test="btn_submit"
            type="submit"
            disabled={
              !passWordValidate.hasCapitalLetters ||
              !passWordValidate.hasNumbers ||
              !passWordValidate.specialCharacters ||
              !passWordValidate.hasNumbers
            }
            className={`w-full rounded-lg flex m-auto text-center justify-center items-center mt-4 ${
              !passWordValidate.hasCapitalLetters ||
              !passWordValidate.hasNumbers ||
              !passWordValidate.specialCharacters ||
              !passWordValidate.hasNumbers
                ? "bg-gray-300"
                : "bg-custom-gradient shadow-login  hover:opacity-70"
            } py-3 text-white font-extrabold border-none`}
          >
            CONFIRMAR
          </button>
        </form>
      </div>
    </div>
  );
}
