import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().loginSlice.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const inviteLinkApi = createApi({
  reducerPath: "inviteLinkApi",
  baseQuery,
  tagTypes: ["Link"],
  endpoints: (builder) => ({
    inviteLinkMe: builder.query({
      query: () => "/api/v1/invite_links/all/me",
      providesTags: ["Link"],
    }),
    inviteLinkReferral: builder.query({
      query: () => {
        return {
          url: "/api/v1/invite_links/referral",
          method: "POST",
          body: {
            alias: "Link para empresa",
          },
        };
      },
      invalidatesTags: ["Company"],
    }),
    inviteFirmUser: builder.query({
      query: () => {
        return {
          url: "/api/v1/invite_links/firm_user",
          method: "POST",
          body: {
            alias: "Link para empresa",
          },
        };
      },
      invalidatesTags: ["Company"],
    }),
  }),
});

export const {
  useInviteLinkMeQuery,
  useInviteFirmUserQuery,
  useInviteLinkReferralQuery,
} = inviteLinkApi;
