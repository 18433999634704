import { loginApi } from "../services/loginApi";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: null,
  loading: false,
  token: null,
};

const loginSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(loginApi.endpoints.authUser.matchPending, (state) => {
      state.loading = true;
    });
    builder.addMatcher(
      loginApi.endpoints.authUser.matchFulfilled,
      (state, { payload }) => {
        state.token = payload?.access_token;
      }
    );
    builder.addMatcher(
      loginApi.endpoints.authUser.matchRejected,
      (state, action) => {
        state.error = action?.payload;
      }
    );
  },
});

export default loginSlice.reducer;

export const { logout } = loginSlice.actions;
