import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import { Button, Input } from "antd";
import { FiLock } from "react-icons/fi";
import { MdMailOutline } from "react-icons/md";
import { useAuthUserMutation } from "../../../store/services/loginApi";

const schema = z.object({
  username: z.string().min(1, "Usuário é obrigatório"),
  password: z.string().min(1, "Senha é obrigatório"),
});

export default function Login() {
  const navigate = useNavigate();

  const [authUser, { isLoading }] = useAuthUserMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: zodResolver(schema),
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const handleLogin = useCallback(
    (event) => {
      const username = event.username;
      const password = event.password;

      const result = authUser({ username, password });

      result?.then((res) => {
        if (Object.keys(res).includes("error")) {
          toast.error("Usuário ou senha invalido");
        } else {
          navigate("/dashboard");
        }
      });
    },
    [authUser, navigate]
  );

  return (
    <div className="w-full xl:w-6/12 flex justify-center">
      <form
        onSubmit={handleSubmit(handleLogin)}
        className="flex flex-col items-center"
      >
        <h1 className="text-[54px] text-center text-primary font-semibold">
          Bem-vindo!
        </h1>
        <div className="gap-3 p-5 flex relative flex-col rounded-2xl max-w-[450px] w-full shadow-login">
          <div className=" flex items-center ">
            <span className="w-2 h-[31px] bg-customPrimary me-4" />
            <span className="text-[22px] md:text-[26px] font-semibold mt-2 !m-0">
              Área de acesso ao sistema
            </span>
          </div>
          <div className="w-full flex flex-col">
            <Controller
              control={control}
              name="username"
              render={({ field }) => (
                <div className="w-full flex flex-col">
                  <Input
                    className="text-[16px] px-3 py-3 rounded-md bg-[#E8E8E8] hover:border-purpleLight focus:border-purpleLight focus-within:border-purpleLight focus:shadow-none"
                    {...field}
                    type="text"
                    size="large"
                    id="username"
                    placeholder="Email"
                    prefix={<MdMailOutline color="#5e5e5e" className="me-2" />}
                    status={errors?.username ? "error" : ""}
                  />
                </div>
              )}
            />
            {errors?.username && (
              <span className="text-[#fe3535] text-sm mt-1">
                {errors?.username.message}
              </span>
            )}
          </div>
          <div className="w-full flex flex-col">
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <div className="w-full flex flex-col">
                  <Input.Password
                    className="text-[16px]  px-3 py-3 rounded-md bg-[#E8E8E8] hover:border-purpleLight  focus:border-purpleLight focus-within:border-purpleLight focus:shadow-none "
                    type="password"
                    size="large"
                    placeholder="Senha"
                    id="password"
                    {...field}
                    prefix={<FiLock color="#5e5e5e" className="me-2" />}
                  />
                </div>
              )}
            />
            {errors?.password && (
              <span className="text-[#fe3535] text-sm mt-1">
                {errors?.password.message}
              </span>
            )}
          </div>
          <div className="flex justify-between">
            <span
              className="decoration-transparent text-black cursor-pointer"
              onClick={() => navigate("/register/user")}
            >
              Cadastrar-se
            </span>{" "}
            <span
              onClick={() => navigate("/login/recovery")}
              className="decoration-transparent text-black cursor-pointer"
            >
              Esqueci minha senha
            </span>
          </div>
          <Button
            className="w-full px-3 py-6 bg-customPrimary text-white font-bold border-none hover:opacity-80 rounded-md shadow-login"
            htmlType="submit"
            size="large"
            loading={isLoading}
          >
            ENTRAR
          </Button>
        </div>
      </form>
    </div>
  );
}
