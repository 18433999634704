import { Button } from "antd";
import { LuCopy } from "react-icons/lu";

import React from "react";

import toast from "react-hot-toast";

const listIcons = [
  {
    name: "Facebook",
    image: "/img/svg/icons/socialMidia/facebook.svg",
  },
  {
    name: "Instagram",
    image: "/img/svg/icons/socialMidia/instagram.svg",
  },
  {
    name: "WhatsApp",
    image: "/img/svg/icons/socialMidia/whatsapp.svg",
  },
  {
    name: "Outlook",
    image: "/img/svg/icons/socialMidia/ms_outlook.svg",
  },
  {
    name: "Gmail",
    image: "/img/svg/icons/socialMidia/gmail.svg",
  },
];

export default function InvitationLink(link) {
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(link.link);
      return toast.success("Link copiado com sucesso!");
    } catch (err) {
      console.error("Falha ao copiar o link:", err);
    }
  };

  const handleShare = (socialMidia) => {
    const subject = "Confira este site incrível!";
    const body = `Olá, gostaria de compartilhar este site com você: ${link}`;

    switch (socialMidia) {
      case "Facebook":
        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          link
        )}`;
        return window.open(facebookShareUrl, "_blank", "noopener,noreferrer");
      case "Instagram":
        const instagramUrl = `https://www.instagram.com/direct/new/?text=${encodeURIComponent(
          link
        )}`;
        return window.open(instagramUrl, "_blank", "noopener,noreferrer");
      case "WhatsApp":
        const message = "Venha fazer parte da minha empresa no site Lexia";
        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          `${message} ${link}`
        )}`;
        return window.open(whatsappUrl, "_blank", "noopener,noreferrer");
      case "Outlook":
        const mailtoUrl = `mailto:?subject=${encodeURIComponent(
          subject
        )}&body=${encodeURIComponent(body)}`;

        return (window.location.href = mailtoUrl);
      case "Gmail":
        const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&su=${encodeURIComponent(
          subject
        )}&body=${encodeURIComponent(body)}`;

        return window.open(gmailUrl, "_blank", "noopener,noreferrer");
      default:
        break;
    }
  };

  return (
    <div className="w-full flex flex-col gap-4 items-start md:mt-3">
      <div className="w-full flex flex-col md:flex-row gap-4 justify-between ">
        <div className="flex gap-3 items-center justify-center">
          {listIcons.map((item, index) => (
            <img
              key={index}
              src={item.image}
              onClick={() => handleShare(item.name)}
              alt=""
              className="cursor-pointer"
            />
          ))}
        </div>
        <Button
          size="large"
          className="bg-[#979797] text-white px-9 flex items-center font-poppins font-medium border-none hover:opacity-65 rounded-md"
          disabled={!link}
          onClick={handleCopy}
        >
          <LuCopy />
          copiar link
        </Button>
      </div>
    </div>
  );
}
