import React, { useCallback, useState } from "react";
import { Button, Input, Modal } from "antd";
import { Controller, useForm } from "react-hook-form";
import CustomInputMask from "../../CustomInputMask";
import { zodResolver } from "@hookform/resolvers/zod";
import Attachment from "../../Attachment";

import { z } from "zod";
import ResetPassword from "../ResetPassword";
import {
  useGetMyUserQuery,
  useUserInfoUpdateMutation,
} from "../../../store/services/userApi";
import { useFindAddressByCepMutation } from "../../../store/services/findCepApi";
import toast from "react-hot-toast";
import { state } from "../../../data/state";

const schema = z.object({
  id: z.any().optional(),
  full_name: z.string().min(1, "Nome é obrigatório"),
  photo: z.string().optional(),
  email: z.string().email("Email é obrigatório"),
  phone: z.string().min(1, "Telefone é obrigatório"),
  addressCode: z.string().min(1, "Cep é obrigatório"),
  city: z.string().min(1, "Cidade é obrigatório"),
  street: z.string().min(1, "Rua é obrigatório"),
  state: z.string().min(1, "Estado é obrigatório"),
  neighborhood: z.string().min(1, "Bairro é obrigatório"),
  number: z.string().optional(),
});

export default function Profile() {
  const { data: userData } = useGetMyUserQuery();
  const [getAddressInfoByCep] = useFindAddressByCepMutation();

  const [updateUserInfo] = useUserInfoUpdateMutation();

  const [openModal, setModalOpen] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: zodResolver(schema),
    values: {
      ...userData,
      id: userData?.id,
    },
    defaultValues: {
      id: undefined,
      full_name: "",
      photo: "",
      email: "",
      phone: "",
      addressCode: "",
      city: "",
      street: "",
      state: "",
      neighborhood: "",
      number: "",
    },
  });

  const handleFormSubmit = useCallback(
    (data) => {
      const response = updateUserInfo({
        email: data?.email,
        is_active: true,
        is_superuser: true,
        full_name: data?.full_name,
        id: data?.id,
        photo: data.photo,
        phone: data.phone.replace(/\s+/g, ""),
        addressCode: data.addressCode,
        city: data.city,
        street: data.street,
        state: data.state,
        neighborhood: data.neighborhood,
        number: data.number,
      });

      response.then((res) => {
        if (Object.keys(res).includes("error")) {
          toast.error("Erro ao atualizar informações do perfil");
        } else {
          toast.success("Informações atualizadas com sucesso");
        }
      });
    },
    [updateUserInfo]
  );

  const handleGetAddressInfo = useCallback(
    (cep) => {
      if (cep.length === 9) {
        const response = getAddressInfoByCep(cep);

        response.then((res) => {
          if (Object.keys(res).includes("error")) {
            toast.error("Erro ao buscar informações do cep");
          } else {
            const nameState = state[res?.data?.uf];

            setValue("street", res?.data?.logradouro);
            setValue("city", res?.data?.localidade);
            setValue("neighborhood", res?.data?.bairro);
            setValue("state", nameState);
          }
        });
      }
    },
    [getAddressInfoByCep, setValue]
  );

  return (
    <div className="w-full p-4 flex flex-col shadow-login gap-7">
      <span className="text-2xl font-semibold underline underline-offset-8">
        Meu perfil
      </span>
      <div className="w-full md:w-auto flex justify-center xl:justify-start items-center">
        <Attachment setValue={setValue} />
      </div>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className="w-full grid grid-flow-row-dense grid-cols-6 gap-5"
      >
        <div className="col-span-6 md:col-span-2">
          <span className="text-[#5C5C5C] text-sm font-normal">Nome</span>
          <Controller
            control={control}
            name="full_name"
            render={({ field }) => (
              <Input
                size="large"
                {...field}
                status={errors.full_name ? "error" : ""}
                placeholder="Insira o seu Nome"
                className={`${
                  errors?.full_name
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.full_name && (
            <span className="text-[#fe3535] text-sm">
              {errors?.full_name.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 md:col-span-2">
          <span className="text-[#5C5C5C] text-sm font-normal">Email</span>
          <Controller
            control={control}
            name="email"
            render={({ field: { name, onChange, value } }) => (
              <Input
                size="large"
                name={name}
                onChange={onChange}
                value={value}
                status={errors.email ? "error" : ""}
                placeholder="Insira o seu Email"
                className={`${
                  errors?.email
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.email && (
            <span className="text-[#fe3535] text-sm">
              {errors?.email.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 md:col-span-2">
          <span className="text-[#5C5C5C] text-sm font-normal">Telefone</span>
          <Controller
            control={control}
            name="phone"
            render={({ field: { name, onChange, value } }) => (
              <CustomInputMask
                name={name}
                size="large"
                onChange={onChange}
                value={value}
                mask={"(XX) XXXXX-XXXX"}
                replacement={{
                  _: /\d/,
                  X: /[0-9]/,
                }}
                placeholder="Insira o seu Telefone"
                className={`${
                  errors?.phone ? "border-red-600" : ""
                } w-full rounded-sm hover:border-purpleLight focus:border-purpleLight focus:shadow-none`}
              />
            )}
          />
          {errors?.phone && (
            <span className="text-[#fe3535] text-sm">
              {errors?.phone.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 md:col-span-2">
          <span className="text-[#5C5C5C] text-sm font-normal">CEP</span>
          <Controller
            control={control}
            name="addressCode"
            render={({ field: { name, value, onChange } }) => (
              <CustomInputMask
                name={name}
                style={{
                  resize: "none",
                }}
                mask={"_____-___"}
                replacement={{
                  _: /\d/,
                  X: /[0-9]/,
                }}
                onChange={(e) => {
                  onChange(e.target.value);
                  handleGetAddressInfo(e.target.value);
                }}
                value={value}
                placeholder="Insira o seu Cep"
                className={`${
                  errors?.addressCode
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.addressCode && (
            <span className="text-[#fe3535] text-sm">
              {errors?.addressCode.message}
            </span>
          )}
        </div>
        <div className="col-span-6 md:col-span-2">
          <span className="text-[#5C5C5C] text-sm font-normal">Rua</span>
          <Controller
            control={control}
            name="street"
            render={({ field }) => (
              <Input
                {...field}
                size="large"
                placeholder="Insira o nome da rua"
                status={errors.street ? "error" : ""}
                className={`${
                  errors?.street
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.street && (
            <span className="text-[#fe3535] text-sm">
              {errors?.street.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 md:col-span-2">
          <span className="text-[#5C5C5C] text-sm font-normal">Numero</span>
          <Controller
            control={control}
            name="number"
            render={({ field }) => (
              <Input
                {...field}
                size="large"
                type="number"
                status={errors?.number ? "error" : "success"}
                placeholder="Insira o número da rua"
                className={`${
                  errors?.number
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.number && (
            <span className="text-[#fe3535] text-sm">
              {errors?.number.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 md:col-span-2">
          <span className="text-[#5C5C5C] text-sm font-normal">Bairro</span>
          <Controller
            control={control}
            name="neighborhood"
            render={({ field }) => (
              <Input
                {...field}
                size="large"
                status={errors?.neighborhood ? "error" : ""}
                placeholder="Insira o nome do bairro"
                className={`${
                  errors?.neighborhood
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.neighborhood && (
            <span className="text-[#fe3535] text-sm">
              {errors?.neighborhood.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 md:col-span-2">
          <span className="text-[#5C5C5C] text-sm font-normal">Estado</span>
          <Controller
            control={control}
            name="state"
            render={({ field }) => (
              <Input
                {...field}
                size="large"
                status={errors?.state ? "error" : ""}
                placeholder="Insira o nome do Estado"
                className={`${
                  errors?.state
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.state && (
            <span className="text-[#fe3535] text-sm">
              {errors?.state.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 md:col-span-2">
          <span className="text-[#5C5C5C] text-sm font-normal">Cidade</span>
          <Controller
            control={control}
            name="city"
            render={({ field }) => (
              <Input
                {...field}
                size="large"
                status={errors?.city ? "error" : ""}
                placeholder="Insira o Complemento"
                className={`${
                  errors?.city
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.city && (
            <span className="text-[#fe3535] text-sm">
              {errors?.city.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 gap-3 md:gap-0 flex flex-col md:flex-row justify-between mt-4">
          <Button
            size="large"
            className="text-[#212121] border-[#212121] hover:text-red-500 hover:border-red-500 font-semibold rounded px-6"
          >
            CANCELAR
          </Button>
          <div className="flex flex-col md:flex-row gap-3">
            <Button
              size="large"
              className="text-white bg-[#212121] hover:opacity-70 hover:border-[#212121] font-semibold rounded px-6"
              onClick={() => setModalOpen(true)}
            >
              ALTERAR SENHA
            </Button>
            <Button
              size="large"
              htmlType="submit"
              className="text-white bg-[#212121] hover:opacity-70 hover:border-[#212121] font-semibold rounded px-6"
            >
              ATUALIZAR
            </Button>
          </div>
        </div>
      </form>
      <Modal
        open={openModal}
        onCancel={() => setModalOpen(false)}
        width={"680px"}
        footer={<ResetPassword setModalOpen={setModalOpen} />}
      />
    </div>
  );
}
