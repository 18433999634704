import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().loginSlice.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const recoveryPasswordApi = createApi({
  reducerPath: "recoveryPassWordApi",
  baseQuery,
  tagTypes: ["PassWordRecovery"],
  endpoints: (builder) => ({
    recoveryResetPassword: builder.mutation({
      query: ({ password, token }) => ({
        url: `/api/v1/reset-password`,
        method: "POST",
        body: {
          new_password: password,
          token,
        },
      }),
      invalidatesTags: ["PassWordRecovery"],
    }),
  }),
});

export const { useRecoveryResetPasswordMutation } = recoveryPasswordApi;
