import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().loginSlice.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const companyApi = createApi({
  reducerPath: "companyApi",
  baseQuery,
  tagTypes: ["Company"],
  endpoints: (builder) => ({
    registerCompany: builder.mutation({
      query: ({ company, token }) => {
        return {
          url: "/api/v1/firm/low_friction/",
          method: "POST",
          body: company,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      invalidatesTags: ["Company"],
    }),
    companyByID: builder.query({
      query: (id) => `/api/v1/firm/${id}`,
      providesTags: ["Company"],
    }),
    updateCompany: builder.mutation({
      query: (data) => ({
        url: `api/v1/firm/${data.id}`,
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["Company"],
    }),
  }),
});

export const {
  useRegisterCompanyMutation,
  useCompanyByIDQuery,
  useUpdateCompanyMutation,
} = companyApi;
