import React from "react";
import { InputMask } from "@react-input/mask";

export default function CustomInputMask({
  className,
  mask,
  replacement,
  onChange,
  type,
  value,
  style,
  disabled,
  placeholder,
  padding = "py-[7px]",
}) {
  if (replacement && mask) {
    return (
      <InputMask
        mask={mask}
        value={value}
        type={type}
        placeholder={placeholder}
        style={style}
        disabled={disabled}
        className={`${className}  border border-[#d9d9d9] rounded-md focus:outline-none focus:border-1 focus:border-[#1677ff]  px-3 ${padding}`}
        replacement={replacement}
        onChange={onChange}
      />
    );
  }
  return (
    <input
      value={value}
      type={type}
      disabled={disabled}
      className={`${className}  border border-[#d9d9d9] rounded-md focus:outline-none focus:border-1 focus:border-[#1677ff]  px-3 ${padding}`}
      placeholder={placeholder}
      style={style}
      onChange={onChange}
    />
  );
}
