import { createSlice } from "@reduxjs/toolkit";
import { userApi } from "../services/userApi";

const initialState = {
  error: null,
  loading: false,
  userData: null,
};

const userSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(userApi.endpoints.getMyUser.matchPending, (state) => {
      state.loading = true;
    });
    builder.addMatcher(
      userApi.endpoints.getMyUser.matchFulfilled,
      (state, { payload }) => {
        state.userData = payload;
      }
    );
    builder.addMatcher(
      userApi.endpoints.getMyUser.matchRejected,
      (state, action) => {
        state.error = action?.payload;
      }
    );
  },
});

export default userSlice.reducer;

export const { logout } = userSlice.actions;
