import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Input } from "antd";
import React, { useCallback, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import toast from "react-hot-toast";
import { useCreateUserMutation } from "../../../store/services/userApi";

import InvitationLink from "../../InvitationLink/InvitationLink";
import { useInviteFirmUserQuery } from "../../../store/services/inviteLinkApi";

export default function CreateUser({ setModalOpen, userInfo, setUserInfo }) {
  const { data: linkInfo } = useInviteFirmUserQuery();

  const [createUser, { error, isLoading: isLoadingCreate }] =
    useCreateUserMutation();

  const schema = z.object({
    full_name: z
      .string({
        required_error: "Nome de usuário é obrigatório",
      })
      .min(1, "Nome de usuário é obrigatório"),
    email: z.string().email("Email é obrigatório"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onSubmit",
    values: userInfo,
    resolver: zodResolver(schema),
  });

  const link = useMemo(() => {
    return linkInfo?.invite_link;
  }, [linkInfo]);

  const handleFormSubmit = useCallback(
    (data) => {
      const response = createUser(data);

      void response.then((res) => {
        if (Object.keys(res).includes("error")) {
          if (error?.status === 403) {
            toast.error("Sem permissão para criar usuário");
          }
          if (
            res.error.data.detail.includes(
              "email already exists in the system."
            )
          ) {
            return toast.error("Email já registrado.");
          }

          return toast.error("Erro ao criar usuário");
        } else {
          toast.success("Usuário criado com sucesso!");
          setModalOpen(false);
          setUserInfo(undefined);
        }
      });
    },
    [createUser, error?.status, setModalOpen, setUserInfo]
  );

  useEffect(() => {
    if (!userInfo) {
      reset({
        full_name: undefined,
        password: undefined,
      });
    }
  }, [reset, userInfo]);

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className="w-full flex flex-col bg-white px-8 py-6 gap-4 font-poppins"
    >
      <span className="text-center md:text-start text-base text-[#5E5E5E]">
        O seu plano possuí <b>30 licenças</b> de usuários ao sistema.
      </span>
      <span className="text-[#40464F] text-base">Adicionar por link:</span>
      <InvitationLink link={link} />
      <hr />
      <span className="text-[#40464F] text-base">ou, criar manualmente:</span>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className="w-full grid grid-flow-row-dense grid-cols-6 gap-5"
      >
        <div className="col-span-6">
          <span className="text-[#5C5C5C] text-sm font-normal">
            Nome do usuário
          </span>
          <Controller
            control={control}
            name="full_name"
            render={({ field }) => (
              <Input
                size="large"
                {...field}
                status={errors.full_name ? "error" : ""}
                placeholder="Insira o seu Nome"
                className={`${
                  errors?.full_name
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.full_name && (
            <span className="text-[#fe3535] text-sm">
              {errors?.full_name.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6">
          <span className="text-[#5C5C5C] text-sm font-normal">Email</span>
          <Controller
            control={control}
            name="email"
            render={({ field: { name, onChange, value } }) => (
              <Input
                size="large"
                name={name}
                onChange={onChange}
                value={value}
                status={errors.email ? "error" : ""}
                placeholder="Insira o seu Email"
                className={`${
                  errors?.email
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.email && (
            <span className="text-[#fe3535] text-sm">
              {errors?.email.message}
            </span>
          )}
        </div>
      </form>
      <div className="w-full flex gap-4 mt-3">
        <Button
          className="w-full  flex items-center justify-center font-poppins font-semibold text-[#BDBDBD] border-[#BDBDBD] hover:text-red-500 hover:border-red-500 rounded-md"
          size="large"
          onClick={() => {
            setModalOpen(false);
            setUserInfo(null);
            reset();
          }}
        >
          Cancelar
        </Button>
        <Button
          className="w-full flex items-center justify-center font-semibold font-poppins text-white bg-[#212121] hover:opacity-70 hover:border-[#212121] rounded-md"
          size="large"
          loading={isLoadingCreate}
          htmlType="submit"
        >
          Criar usuário
        </Button>
      </div>
    </form>
  );
}
