import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Checkbox, Input } from "antd";
import React, { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import toast from "react-hot-toast";
import CustomInputMask from "../../CustomInputMask";
import Attachment from "../../Attachment";
import { useNavigate, useParams } from "react-router-dom";
import { useRegisterUserMutation } from "../../../store/services/loginApi";

export default function RegisterUser({ onSubmit, stepData, setStep }) {
  const navigate = useNavigate();

  const { id, token } = useParams();

  const [registerUser, { error, isLoading: isLoadingRegister }] =
    useRegisterUserMutation();

  const schema = z.object({
    full_name: z
      .string({
        required_error: "Nome de usuário é obrigatório",
      })
      .min(1, "Nome de usuário é obrigatório"),
    photo: z.string().optional(),
    cpf: z
      .string({
        required_error: "CPF é obrigatório",
      })
      .min(1, "CPF é obrigatório"),
    email: z.string().email("Email é obrigatório"),
    terms: z.boolean({
      required_error: "Aceitar os termos é obrigatório",
    }),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onSubmit",
    values: stepData ?? undefined,
    resolver: zodResolver(schema),
  });

  const handleFormSubmit = useCallback(
    (data) => {
      onSubmit && onSubmit(data);

      const customData = {
        full_name: data.full_name,
        email: data.email,
        cpf: data.cpf.replace(/\D/g, ""),
        link_id: token,
      };

      if (id === "user") {
        const response = registerUser(customData);

        return response.then((res) => {
          if (Object.keys(res).includes("error")) {
            if (error?.status === 403) {
              toast.error("Não foi possível criar usuário");
            }
            return toast.error("Erro ao criar usuário");
          } else {
            navigate("/register/success");
          }
        });
      }
    },
    [error?.status, id, navigate, onSubmit, registerUser, token]
  );

  return (
    <div className="w-full h-fit flex-col flex justify-center items-center font-poppins m-auto">
      <div className="gap-3 p-5 flex relative flex-col rounded-2xl w-full xs:py-6  px-6 md:px-24">
        <div className="flex items-center ">
          <span className="w-1 h-8 p-1 bg-[#003863] me-4" />
          <h3 className="text-[16px] xs:text-[20px] md:text-2xl font-semibold">
            Crie seu cadastro abaixo
          </h3>
        </div>
        <div className="w-full mt-3">
          <span className="font-normal text-[#40464F]">
            Para o cadastro em nosso sistema é necessário preencher o formulário
            abaixo com os respectivos dados cadastrais.
          </span>
        </div>
        <div className="w-full md:w-auto flex justify-center items-center">
          <Attachment setValue={setValue} />
        </div>

        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="w-full grid grid-flow-row-dense grid-cols-6 gap-4"
        >
          <div className="col-span-6 md:col-span-3">
            <span className="text-[#5C5C5C] text-sm font-normal flex">
              CPF<p className="text-red-500">*</p>
            </span>
            <Controller
              control={control}
              name="cpf"
              render={({ field: { name, onChange, value } }) => (
                <CustomInputMask
                  name={name}
                  style={{
                    resize: "none",
                    borderColor: errors?.cpf ? "red" : "",
                  }}
                  mask={"___.___.___-__"}
                  replacement={{
                    _: /\d/,
                    X: /[0-9]/,
                  }}
                  dataTestValue="input_cnpj_client"
                  onChange={onChange}
                  value={value}
                  status={errors.cpf ? "error" : ""}
                  className={`${
                    errors?.cpf
                      ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                      : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                  }  w-full rounded-sm h-10 placeholder:text-[#c4c4c4]`}
                  placeholder={"Insira o seu CPF"}
                />
              )}
            />
            {errors?.cpf && (
              <span className="text-[#fe3535] text-sm">
                {errors?.cpf.message}
              </span>
            )}
          </div>
          <div className="col-span-6 md:col-span-3">
            <span className="text-[#5C5C5C] text-sm font-normal flex">
              Nome<p className="text-red-500">*</p>
            </span>
            <Controller
              control={control}
              name="full_name"
              render={({ field }) => (
                <Input
                  size="large"
                  {...field}
                  status={errors.full_name ? "error" : ""}
                  placeholder="Insira o seu Nome"
                  className={`${
                    errors?.full_name
                      ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                      : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                  } w-full rounded-sm `}
                />
              )}
            />
            {errors?.full_name && (
              <span className="text-[#fe3535] text-sm">
                {errors?.full_name.message}
              </span>
            )}
          </div>{" "}
          <div className="col-span-6 ">
            <span className="text-[#5C5C5C] text-sm font-normal flex">
              Email
              <p className="text-red-500">*</p>
            </span>
            <Controller
              control={control}
              name="email"
              render={({ field: { name, onChange, value } }) => (
                <Input
                  size="large"
                  name={name}
                  onChange={onChange}
                  value={value}
                  status={errors.email ? "error" : ""}
                  placeholder="Insira o seu Email"
                  className={`${
                    errors?.email
                      ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                      : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                  } w-full rounded-sm `}
                />
              )}
            />
            {errors?.email && (
              <span className="text-[#fe3535] text-sm">
                {errors?.email.message}
              </span>
            )}
          </div>{" "}
          <div className="col-span-6">
            <Controller
              control={control}
              name="terms"
              render={({ field: { onChange } }) => (
                <Checkbox
                  onChange={onChange}
                  className="text-[#666666] flex text-base"
                >
                  {" "}
                  Ao continuar, concorda com os nossos 
                  <span className="underline text-blue-500">
                    Termos de uso.
                  </span>
                </Checkbox>
              )}
            />
            {errors?.terms && (
              <span className="w-full text-start text-[#fe3535] text-sm">
                {errors?.terms?.message}
              </span>
            )}
          </div>
          {id !== "user" && (
            <div className="col-span-6 flex py-4 gap-16 justify-center">
              {Array.from({ length: 2 }).map((_, index) => (
                <span
                  key={index}
                  className={`w-4 h-4 rounded-full  ${
                    index <= 1 ? "bg-[#D1CECE]" : "bg-[#F2F1F1]"
                  } p-3`}
                />
              ))}
            </div>
          )}
          <div className="col-span-6 gap-3 flex flex-col md:flex-row justify-between mt-3">
            <Button
              size="large"
              type="primary"
              className="w-full md:w-auto md:min-w-52 bg-white border-[#003863] text-[#003863] font-bold rounded py-5 hover:opacity-70"
              onClick={() => {
                if (id === "user") {
                  return navigate("/");
                }
                setStep(0);
              }}
            >
              {id !== "user" ? "VOLTAR" : "CANCELAR"}
            </Button>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              className="w-full md:w-auto md:min-w-52 bg-[#C59012] font-bold rounded py-5 hover:opacity-70"
              loading={isLoadingRegister}
            >
              CADASTRAR
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
