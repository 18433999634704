import { MdOutlineRestartAlt } from "react-icons/md";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { Input } from "antd";
import { IoMdSend } from "react-icons/io";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { useSendQuestionRagMutation } from "../../store/services/chatApi";
import toast from "react-hot-toast";
import ReactMarkdown from "react-markdown";

const listSuggestionMessage = [
  "“Como posso redigir um contrato de locação que inclua cláusulas de rescisão antecipada?”",
  "“Qual seria a melhor estratégia para defender um cliente acusado de [crime específico]?”",
  "“Quais são as mudanças recentes na lei de proteção de dados?”",
];

export default function Chat({ isSidebarOpen }) {
  const { id } = useParams();
  const container = useRef(null);

  const [
    sendQuestionRag,
    { data: responseRequest, isLoading, status, isError },
  ] = useSendQuestionRagMutation();

  const { processData } = useAppSelector((state) => {
    return {
      processData: state.processSlice?.process,
    };
  });

  const [openChat, setChatOpen] = useState(false);
  const [msgChat, setMsgChat] = useState(null);
  const [listLastRequest, setListLastRequest] = useState(null);
  const [sessionID, setSessionID] = useState(null);
  const [haveNotifications, setHaveNotifications] = useState(false);

  const handleSendQuestionRag = useCallback(
    (id, question, session_id) => {
      const response = sendQuestionRag(id, question, session_id);

      response.then((res) => {
        if (Object.keys(res).includes("error")) {
          setMsgChat(msgChat);

          if (res.error.status === 404) {
            return toast.error(
              "Chat indisponível, estou coletando informações sobre seu processo. Tente novamente mais tarde."
            );
          }
          return toast.error(
            "Ops! Não consegui processar sua mensagem no momento. Tente novamente."
          );
        }
        setMsgChat();
        setListLastRequest((prev) => {
          if (prev && prev?.length > 0) {
            return prev.concat({
              question: res?.data?.question,
              answer: res?.data?.answer,
            });
          }
          return [
            {
              question: res?.data?.question,
              answer: res?.data?.answer,
            },
          ];
        });
        setSessionID(res?.data?.session_id);
      });
    },
    [msgChat, sendQuestionRag]
  );

  useEffect(() => {
    if (!openChat && responseRequest) {
      setHaveNotifications(true);
    }
    //TODO: Está usando o status apenas no array de dependência para apenas disparar o useEffect quando for feita uma nova requisição
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const scrollToBottom = useCallback(() => {
    if (container.current) {
      container.current.scrollTop = container.current.scrollHeight;
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listLastRequest, openChat, isLoading]);

  return (
    <div
      className={`${isSidebarOpen ? "ms-[323px]" : "ms-[80px]"} ${
        processData?.process_number && isSidebarOpen
          ? "w-full md:w-9/12 xl:w-5/12 2xl:w-2/5  "
          : "w-auto"
      } ${
        openChat ? "xl:w-full md:min-w-[380px]" : ""
      } fixed bottom-0 md:max-w-[850px]`}
      onClick={() => {
        if (!openChat) {
          setChatOpen(true);
          setHaveNotifications(false);
        }
      }}
    >
      <div className="w-full flex px-3 gap-4 text-white items-center  bg-customPrimary p-3 rounded-t-2xl relative">
        <img src="/img/svg/logoBiofyB.svg" alt="" className="ms-3" />
        {processData?.process_number ? (
          <span className="w-full text-[10px] font-bold md:text-base ">
            Como posso te ajudar com o processo{" "}
            {processData?.process_number && `(${processData?.process_number})`}{" "}
            ?
          </span>
        ) : (
          <span className="w-full font-bold pe-4 ">Posso te ajudar ?</span>
        )}

        {openChat && (
          <>
            <MdOutlineRestartAlt
              title="limpar conversa"
              className="ms-auto cursor-pointer"
              onClick={() => {
                setSessionID();
                setListLastRequest();
              }}
              size={23}
            />
            <IoCloseOutline
              title="fechar"
              className="ms-auto cursor-pointer"
              onClick={() => {
                setHaveNotifications(false);
                setChatOpen(false);
              }}
              size={23}
            />
          </>
        )}
        {haveNotifications && (
          <span className="w-7 h-7 rounded-full  text-white absolute bg-red-600 text-center flex items-center justify-center top-[-18px] right-2 font-bold">
            1
          </span>
        )}
      </div>
      <div
        className={`w-full relative overflow-auto  bg-[#FAFAFA] p-3 ${
          openChat ? "flex h-[400px]" : "hidden h-auto"
        }  flex-col gap-3`}
      >
        {processData?.process_number && (
          <span className="w-full text-xs md:text-base flex justify-center gap-2">
            <b>N° processo: </b>
            {processData?.process_number}
          </span>
        )}

        <div className="w-full h-full text-xs md:text-base flex flex-col overflow-auto justify-between relative gap-4 py-2 ">
          {listLastRequest && listLastRequest.length > 0 ? (
            <div
              className="w-full flex flex-col gap-4 h-96 overflow-auto py-4 px-2"
              ref={container}
            >
              {listLastRequest?.map((item, index) => (
                <div className={`w-full flex flex-col gap-3`} key={index}>
                  <ReactMarkdown className="max-w-3xl ms-auto flex bg-white px-5 p-2 !mb-0  justify-end items-end rounded-2xl border rounded-ee-none border-[#d0d5dd] text-base">
                    {item.question}
                  </ReactMarkdown>
                  <div className="w-11/12 flex gap-2 items-center ms-end px-5 p-2 rounded-2xl font-light">
                    {/* <FaUserCircle size={55} className="w-32" /> */}
                    <ReactMarkdown className="me-auto">
                      {item.answer}
                    </ReactMarkdown>
                  </div>
                  {isLoading && listLastRequest.length - 1 === index ? (
                    <>
                      <div className="ms-auto ">
                        <ReactMarkdown className="max-w-3xl ms-auto !mb-0 flex bg-white px-5 p-2 justify-end items-end rounded-2xl border rounded-ee-none border-[#d0d5dd] text-base">
                          {msgChat}
                        </ReactMarkdown>
                      </div>
                      <div className="w-full  flex flex-col overflow-auto gap-2">
                        <span className="ms-5 loader" />
                      </div>
                    </>
                  ) : null}
                  {isError && listLastRequest.length - 1 === index ? (
                    <div className="w-auto flex flex-col text-center md:flex-row gap-2 mx-auto border bg-red-50 border-red-500 p-2 text-red rounded-md">
                      <span>Error ao enviar mensagem:</span>
                      <span
                        className="font-semibold cursor-pointer"
                        onClick={() =>
                          handleSendQuestionRag({
                            id,
                            question: msgChat,
                            session_id: sessionID,
                          })
                        }
                      >
                        Enviar novamente
                      </span>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          ) : (!listLastRequest && !isLoading) ||
            (listLastRequest?.length === 0 && !isLoading) ? (
            <div className="w-full flex flex-col px-3 gap-3 text-[#212121] mt-12">
              {listSuggestionMessage.map((item, index) => (
                <span
                  className="underline cursor-pointer"
                  key={index}
                  onClick={() => {
                    setMsgChat(item);
                    handleSendQuestionRag({
                      id,
                      question: item,
                      session_id: sessionID,
                    });
                  }}
                >
                  {item}
                </span>
              ))}
            </div>
          ) : isLoading ? (
            <>
              <div className="ms-auto ">
                <ReactMarkdown className="ms-auto flex justify-end items-end px-5 p-2 rounded-2xl border rounded-ee-none bg-[#FFFFFF] border-[#d0d5dd] text-base">
                  {msgChat}
                </ReactMarkdown>
              </div>
              <div className="w-full  flex flex-col overflow-auto gap-2">
                <span className="ms-5 loader" />
              </div>
            </>
          ) : null}
          <div className="w-full flex justify-center items-end relative bottom-0 py-2">
            <Input
              size="large"
              className="rounded-full shadow-login"
              placeholder="Digite sua dúvida..."
              onPressEnter={() => {
                handleSendQuestionRag({
                  id,
                  question: msgChat,
                  session_id: sessionID,
                });
              }}
              onChange={(e) => setMsgChat(e.target.value)}
              value={msgChat}
              disabled={isLoading}
              suffix={
                <div
                  className="bg-[#BCBCBC4D] rounded-full p-2 cursor-pointer"
                  onClick={() => {
                    handleSendQuestionRag({
                      id,
                      question: msgChat,
                      session_id: sessionID,
                    });
                  }}
                >
                  <IoMdSend size={12} color="#989898" />
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
