import React from "react";
import DocumentCard from "../DocumentSummaryCard";
import TimelineList from "../TimeLineList";

const renderEvent = (document, index, typeResume) => {
  return (
    document?.document_datetime && (
      <DocumentCard
        key={index}
        typeResume={typeResume}
        document={{ ...document, ...document.parsed_summary }}
      />
    )
  );
};

const ProcessDocumentList = ({ documents, process }) => {
  const parsedDocuments = (documents) =>
    documents.data.map((document) => {
      return { ...document, ...document.parsed_summary };
    });
  return (
    documents?.data && (
      <TimelineList
        items={parsedDocuments(documents)}
        renderItem={renderEvent}
        dateField="document_datetime"
        sortAscending={false}
        process={process}
      />
    )
  );
};

export default ProcessDocumentList;
