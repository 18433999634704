import React from "react";

const Loading = ({ width, height, bgColor }) => {
  return (
    <div
      className={`flex justify-center items-center h-full ${
        bgColor ? bgColor : "bg-light"
      } `}
    >
      <div
        className="border-[16px] border-light border-t-[16px] border-t-primary rounded-full h-32 w-32 animate-loading"
        style={{ width, height }}
      />
    </div>
  );
};

export default Loading;
