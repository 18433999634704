import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { TbUsers } from "react-icons/tb";
import { FiGrid, FiSettings } from "react-icons/fi";
import { useAppSelector } from "../../store/hooks";

const listSelect = [
  {
    id: 0,
    name: "Tela inicial",
    router: "/dashboard",
    secondaryRoute: "/process",
    icon: <FiGrid size={30} />,
  },
  {
    id: 1,
    name: "Gestão de Pessoas",
    router: "/people",
    icon: <TbUsers size={30} />,
  },
  {
    id: 2,
    name: "Gerenciar empresa",
    router: "/config/system",
    icon: <FiSettings size={30} />,
  },
];

export default function CustomSideBar({ isSidebarOpen, toggleSidebar }) {
  const location = useLocation();

  const { userInfo } = useAppSelector((state) => {
    return {
      userInfo: state.userSlice,
    };
  });

  const newList = useMemo(() => {
    if (userInfo?.userData?.is_admin || userInfo?.userData?.is_superuser) {
      return listSelect;
    }
    return listSelect.filter((item) => item.id === 0);
  }, [userInfo?.userData?.is_admin, userInfo?.userData?.is_superuser]);

  return (
    <header
      className={`w-full h-fullNav md:h-full bg-[#212121] fixed select-none pt-4 ${
        isSidebarOpen
          ? "w-svw absolute min-w-[315px] 4xl:w-[400px] px-4 md:w-auto"
          : "hidden"
      }   text-white  flex flex-col justify-between z-40`}
    >
      <div className="flex flex-col gap-3 relative h-full">
        {newList?.map((item) => (
          <React.Fragment key={item.id}>
            {item.name !== "Gerenciar empresa" ? (
              <div
                className={`w-full flex flex-col justify-between p-3 rounded-lg ${
                  location.pathname.includes(item.router) ||
                  location.pathname.includes(item.secondaryRoute) ||
                  (location.pathname === "/" && item.id === 0)
                    ? "bg-custom-gradient text-white"
                    : ""
                } `}
              >
                <Link
                  className={`${
                    !isSidebarOpen ? "w-full items-center justify-center" : ""
                  } ${
                    location.pathname.includes(item.router) ||
                    location.pathname.includes(item.secondaryRoute) ||
                    (location.pathname === "/" && item.id === 0)
                      ? "custom-gradient text-white"
                      : "text-white"
                  }    decoration-transparent  text-lg font-bold flex items-center gap-2 hover:opacity-70`}
                  to={item.router}
                  onClick={toggleSidebar}
                >
                  {item.icon}
                  {isSidebarOpen && item.name}
                </Link>
              </div>
            ) : (
              <div
                key={item.id}
                className={`w-full mt-auto flex flex-col p-3 mb-2 rounded-e-lg ${
                  location.pathname.includes(item.router) ||
                  location.pathname.includes(item.secondaryRoute)
                    ? "bg-customPrimary text-white"
                    : ""
                } `}
              >
                <Link
                  className={`${
                    !isSidebarOpen ? "w-full items-center justify-center" : ""
                  } text-white decoration-transparent  text-lg font-bold flex items-center gap-2 hover:opacity-70 `}
                  to={item.router}
                >
                  {item.icon}
                  {isSidebarOpen && item.name}
                </Link>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </header>
  );
}
