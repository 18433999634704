import { Image, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { useState } from "react";
import { BsUpload } from "react-icons/bs";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default function Attachment({ setValue }) {
  const [imgProfile, setImageProfile] = useState();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState();

  const props = {
    name: "file",
    // action: `${url}/api/upload`,
    action: `url/api/upload`,
    multiple: false,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading" && status !== "removed") {
        setImageProfile("info?.file?.response?.data[0]");
        if (info?.file?.response?.data) {
          setImageProfile(info?.file?.response?.data[0]);
          setValue("photo", info?.file?.response?.data[0]);
        }
      }
    },
    onDrop(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        setPreviewImage(info?.file?.response?.data[0]);
        setValue("photo", info?.file?.response?.data[0]);
        // setImageProfile(info?.file?.response?.data[0])
      }
    },
    defaultFileList: [],
    onRemove() {
      setPreviewImage("");
      setImageProfile("");
      setValue("image", "");
    },
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  return (
    <div className="flex ">
      <ImgCrop rotationSlider quality={1} minZoom={0.5} maxZoom={4}>
        <Upload
          {...props}
          onPreview={handlePreview}
          listType="picture-circle"
          maxCount={1}
          multiple={false}
        >
          {!imgProfile && (
            <div
              className="w-full h-full flex items-center justify-center relative"
              data-text="upload_component_file"
            >
              <BsUpload className="max-w-full" size={35} />
            </div>
          )}
        </Upload>
      </ImgCrop>
      {imgProfile && (
        <Image
          wrapperStyle={{
            display: "none",
          }}
          data-test="view_image"
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(""),
          }}
          src={previewImage}
        />
      )}
    </div>
  );
}
