import React from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const PDFViewer = ({ pdfUrl }) => (
  <Worker
    workerUrl={`https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`}
  >
    <div className="h-[65lvh] flex overflow-auto">
      <Viewer fileUrl={pdfUrl} />
    </div>
  </Worker>
);

export default PDFViewer;
